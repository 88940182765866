export const ImageService = {
    async updateUrl(data) {
        for(var i = 0; i < data.length; i++){
            switch(data[i].provider.toLowerCase().trim()){
                case 'uhm':3
                    data[i].image = this.changeDiag(data[i].image);
                    switch(data[i].brand.toLowerCase().trim()){
                        case 'urrea':
                            data[i].image = "https://medios.urrea.com/catalogo/Urrea/hd/"+data[i].image; 
                            break;
                        case 'surtek':
                            data[i].image = "https://medios.urrea.com/catalogo/Surtek/hd/"+data[i].image; 
                            break;
                        case 'foy':
                            data[i].image = "https://medios.urrea.com/catalogo/Surtek/hd/"+data[i].image; 
                            break;
                        case 'proforza':
                            data[i].image = "https://medios.urrea.com/catalogo/Lock/hd/"+data[i].image; 
                            break;
                        case 'lock':
                            data[i].image = "https://medios.urrea.com/catalogo/Lock/hd/"+data[i].image; 
                            break;
                        case 'prolok':
                            data[i].image = "https://medios.urrea.com/catalogo/Lock/hd/"+data[i].image; 
                            break;
                    }
                break;
                case 'truper':
                    var valor = (data[i].image).includes("/") ? (data[i].image).replace("/","-") : data[i].image;
                    data[i].image = "https://www.truper.com/media/import/imagenes/"+valor.replace(".webp", ".jpg"); 
                break;
                case "robert bosch":
                    data[i].image = "https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/bosch/"+data[i].image; 
                break;
                case 'milwaukee':
                    data[i].image = "https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/Milwaukee/"+data[i].image; 
                break;
                case 'makita':
                    data[i].image = "https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/ImagenMakita/"+data[i].image; 
                break;
                case 'jyrsa':
                    data[i].image = "https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/ImagenMakita/"+data[i].image; 
                break;
                default:
                    data[i].image = "https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/productos/noencontrado/noencontrado.webp";
            }
        }
        
        return data;
    },
    urlExiste(url) {
        var img = new Image();
        img.src = url;
        img.onload = function() {
            callback(true);
        };
        img.onerror = function() {
            callback(false);
        };
        
    },
    changeDiag(prod){
        return prod.replace("/", "-")
    }
}